import React, {Component} from 'react';
import './Header.scss';

export default class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuList: [{
                title:'首页',
                name: '#home',
            },{
                title:'解决方案',
                name: '#resolve',
            },{
                title:'服务',
                name: '#service',
            },{
                title:'关于我们',
                name: '#about',
            }],
        };
    }

    scrollPage = name => {
        if (name == '#home'){
            window.scrollTo(0,0)
        }
    }

    render() {
        return (
            <div class="App-header" >
                <div class="flexHC" >
                    <img src="../../logo.png" style="width: 70px;height: 66px;"  style={{height: '66px',width: '70px'}} />
                    <div class="ml-25 fz-32">贵州紫精泰迹科技有限公司</div>
                </div>
                <div className="flexHC">
                    {
                        this.state.menuList.map((item,index) => {
                            return (
                                <a key={index} href={item.name} onClick={ (name) => this.scrollPage(item.name)}  class="ml-55 fz-28">{item.title}</a>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
}