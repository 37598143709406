import React, {Component} from 'react';
import MenuTitle from "./components/MenuTitle";
import './Advantage.scss';

import sop from '../../assets/images/icon_advantag_sop.png'
import support from '../../assets/images/icon_advantage_support.png'
import quality from '../../assets/images/icon_advantag_quality.png'
import price from '../../assets/images/icon_advantag_price.png'

export default class Advantage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [ [
                    {
                        imageUrl: 'https://zj-offical.oss-cn-beijing.aliyuncs.com/%E7%BB%84%202%402x.png',
                        iconUrl: sop,
                        title: '商业分析',
                        content: '以专业化知识帮助用户分析商业前景，对产品提出合理化建议。隐患问题防患于未然，让产品走的更高、更远。'
                    },
                    {
                        imageUrl: 'https://zj-offical.oss-cn-beijing.aliyuncs.com/%E7%BB%84%203%402x.png',
                        iconUrl: support,
                        title: '全程服务支持',
                        content: '售前咨询一一解答，需求分析，全程高效 沟通，售后问题解决，提供迭代建议。'
                    },
                ], [
                {
                    imageUrl: 'https://zj-offical.oss-cn-beijing.aliyuncs.com/%E7%BB%84%204%402x.png',
                    iconUrl: quality,
                    title: '质量可靠',
                    content: '按照需求，精心打磨每一个细节，出具 产品可行性方案，让项目轻松落地。'
                },
                {
                    imageUrl: 'https://zj-offical.oss-cn-beijing.aliyuncs.com/%E7%BB%84%205%402x.png',
                    iconUrl: price,
                    title: '价格公道',
                    content: '无任何隐形费用，价格透明。低于市场 其它外包公司平均水平。'
                },
            ]
            ]
        };
    }

    render() {
        return (
            <div>
                <MenuTitle  menuTitle="服务优势" menuSubtitle="为什么选择我们？"></MenuTitle>
                <div style={{marginTop: '100px'}} className='list flexVC'>

                    {
                        this.state.list.map((item,index) => {
                            return (
                                <div style={{width: '100%'}} className=' flexVC'>
                                    <div  className='flexHC2 mt-15' style={{background: '#FAFAFA',width: '100%'}}>
                                        <img className='bg-img' src={item[0].imageUrl} />
                                        <div style={{marginLeft: '10vw',display: 'flex',flexDirection: 'column'}}>
                                            <img className='icon' src={item[0].iconUrl}/>
                                            <div style={{color: '#5D5D5D',textAlign: 'left'}} className='fz-30 fw-600 mt-30'>{item[0].title}</div>
                                            <div style={{width: '425px',lineHeight: '60px',textAlign: 'left'}} className='cl-666 fz-24 mt-30'>{item[0].content}</div>
                                        </div>
                                    </div>
                                    <div  className='flexHC mt-15'>
                                        <div style={{marginRight: '10vw',display: 'flex',flexDirection: 'column'}}>
                                            <img className='icon' src={item[1].iconUrl}/>
                                            <div style={{color: '#5D5D5D',textAlign: 'left'}} className='fz-30 fw-600 mt-30'>{item[1].title}</div>
                                            <div style={{width: '425px',lineHeight: '60px',textAlign: 'left'}} className='cl-666 fz-24 mt-30'>{item[1].content}</div>
                                        </div>
                                        <img className='bg-img' src={item[1].imageUrl} />
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

            </div>
        )
    }
}