import './App.scss';
import Header from "./pages/home/Header";
import Banner from "./pages/home/Banner";
import Service from "./pages/home/Service";
import Resolve from "./pages/home/Resolve";
import Procedure from "./pages/home/Procedure";
import Advantage from "./pages/home/Advantage";
import Bottom from "./pages/home/Bottom";
import About from "./pages/home/About";

function App() {
  return (
    <div className="App" >
      <Header/>
      <Banner/>
      <Service/>
      <Resolve/>
      <Procedure/>
      <Advantage/>
      <About/>
      <Bottom/>
    </div>
  );
}

export default App;
