import React, {Component} from 'react';
import MenuTitle from "./components/MenuTitle";
import './About.scss';

export default class About extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div style={{marginTop: '150px'}} id='about'>
                <MenuTitle  menuTitle="关于我们" menuSubtitle="用心服务 筑梦起航？"/>
                <div className='about-box flexHC2'>
                    <div className='content-box cl-666 fz-30'>
                        <p>贵阳紫精泰迹科技有限公司成立于2019年，专注从事软件定制化开发业务。主要业务包括小程序开发、网站开发、app开发以及技术服务我们致力于为企业提供优质的一体化开发服务。</p>
                        <p> 我们是一支充满活力的团队，专注用户体验,我们已为多家企业打造了多款优秀产品。期待我们共同携手，实现精彩飞跃！</p>
                    </div>
                    <img  className='bg-img' src='https://zj-offical.oss-cn-beijing.aliyuncs.com/%E7%9F%A2%E9%87%8F%E6%99%BA%E8%83%BD%E5%AF%B9%E8%B1%A1%402x%20%2812%29.png' />
                </div>
            </div>
        )
    }
}