import React, {Component} from 'react';
import './Bottom.scss';

import phone from '../../assets/images/icon_bottom_phone.png'
import email from '../../assets/images/icon_bottom_email.png'
import hline from '../../assets/images/icon_bottom_hline.png'
export default class Bottom extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    navICP = () => {
        window.open('https://beian.miit.gov.cn/#/Integrated/index', '_brank')
    }

    render() {
        return (
            <div className='bottom-box'>
                <div className='flexHC2' style={{height: '88px'}}>
                    <div  className='fz-28 '  style={{marginLeft: '0px'}}>联系我们-->免费帮您梳理需求-->期待您的来访</div>
                    <div className='line'></div>
                    《
                    <div className='flexHC' style={{marginRight: '50px'}}>
                        <img src={phone} />
                        <div className='fz-28 ml-20'>186-1218-6574</div>
                    </div>
                    <div className='flexHC'>
                        <img src={email} />
                        <div className='fz-28 ml-20'>zijingtaiji@163.com</div>
                    </div>
                </div>
                <img src={hline} style={{width: '77.75%',height: '4px',marginTop: '75px'}} />
                <div className='fz-22' style={{marginTop: '50px'}} ><span style={{ cursor:"pointer"}} onClick={this.navICP}>黔ICP备2021001729号-1</span>&nbsp;&nbsp;&nbsp;&nbsp;版权所有&nbsp;&nbsp;贵州紫精泰迹科技有限公司</div>
            </div>
        )
    }
}
