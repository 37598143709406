import React, {Component} from 'react';
import './Procedure.scss';
import MenuTitle from "./components/MenuTitle";

import business from '../../assets/images/icon_procedure_business.png'
import demand from '../../assets/images/icon_procedure_demand.png'
import development from '../../assets/images/icon_procedure_development.png'
import maintain from '../../assets/images/icon_procedure_maintain.png'
import test from '../../assets/images/icon_procedure_test.png'
import ui from '../../assets/images/icon_procedure_ui.png'
export default class Procedure extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [{
                imageUrl: business,
                title: '网站开发',
                content: '售前咨询，确定合 作意向。定稿初步 方案，签订合同。'
            },{
                imageUrl: demand,
                title: '需求确认',
                content: '双方沟通需求，确 认整体功能。原型 输出，细节敲定。'
            },{
                imageUrl: ui,
                title: 'UI设计',
                content: '功能确认后，开始 输出页面设计。 交付甲方定稿。'
            },{
                imageUrl: development,
                title: '功能开发',
                content: '前端开发页面，后 端开发接口，联调 完后，交付测试。'
            },{
                imageUrl: test,
                title: '测试交付',
                content: 'BUG确认，提交开 发修改。回归测试 后部署环境，交付。'
            },{
                imageUrl: maintain,
                title: '维护咨询',
                content: '修复BUG，后续 产品运营咨询， 迭代开发建议。'
            },]
        };
    }

    render() {
        return (
            <div style={{marginTop: '90px'}}>
                <MenuTitle  menuTitle="软件定制化开发流程" menuSubtitle="一体化服务流程，让项目轻松落地"></MenuTitle>
                <div style={{display: 'flex',justifyContent: 'space-between',padding: '0 200px',margin: '100px 0'}}>
                    {
                        this.state.list.map((item,index) => {
                            return (
                                <div>
                                    <div className='flexVC2 item-top'>
                                        <img src={item.imageUrl}/>
                                        <div className='fz-24 cl-888 fw-600'>{item.title}</div>
                                    </div>
                                    <div className='item-bottom'>
                                        <span>{item.content}</span>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
}