import React, {Component} from 'react';
import MenuTitle from "./components/MenuTitle";

export default class Resolve extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imgUrl: 'https://zj-offical.oss-cn-beijing.aliyuncs.com/%E7%BB%84%208%402x.png'
        };
    }

    render() {
        return (
            <div style={{marginTop: '180px'}} id='resolve'>
                <MenuTitle  menuTitle="解决方案" menuSubtitle="多元化开发，助您解决真实需求"></MenuTitle>
                <div className="flexVC" style={{width: '100%',height: '812px',background: '#F6F6F6',marginTop: '80px'}}>
                    <img src={this.state.imgUrl} style={{width: '1140px',height: '741px', marginTop: '16px'}}/>
                </div>
            </div>
        )
    }
}