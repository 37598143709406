import React, {Component} from 'react';
import './Banner.scss'

export default class Banner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imageUrl: 'https://zj-offical.oss-cn-beijing.aliyuncs.com/%E7%9F%A2%E9%87%8F%E6%99%BA%E8%83%BD%E5%AF%B9%E8%B1%A1%402x%20%282%29.png'
        };
    }

    render() {
        return (
            <div className="container" >
                <div className="left">
                    <div className='banner-title'>
                        <span>专业的</span>
                        <span style={{color: '#FABA29'}}>软件定制化</span>
                        <span>开发</span>
                    </div>
                    <div className="fz-28 cl-666 fw-600" style={{marginTop: '55px'}}>提供多种软件开发服务，助力您事业蒸蒸日上！</div>
                    <div style={{marginTop: '290px', color: '#7A7A7A'}} className="fz-24">贵州紫精泰迹 - 用专业和诚信赢得您的信赖</div>
                </div>
                <div className="right">
                    <img src={this.state.imageUrl} style={{width: '863px',height: '701px'}}/>
                </div>
            </div>
        )
    }
}