import React, {Component} from 'react';

export default class MenuTitle extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="w-100 flexVC">
                <div style={{fontSize: '48px'}} className="fw-600 cl-333">{this.props.menuTitle}</div>
                <div className='mt-20 fw-600 cl-888 fz-28'>{this.props.menuSubtitle}</div>
                <div className='mt-20' style={{width: '100px',height: '6px',background: '#9664C9',borderRadius: '3px'}}></div>
            </div>
        )
    }
}