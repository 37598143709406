import React, {Component} from 'react';
import MenuTitle from "./components/MenuTitle";
import './Service.scss';
import web from '../../assets/images/icon_service_web.png'
import miniApp from '../../assets/images/icon_service_miniapp.png'
import publicImg from '../../assets/images/icon_service_public.png'
import app from '../../assets/images/icon_service_app.png'
import support from '../../assets/images/icon_service_support.png'

export default class Service extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [{
                imageUrl: web,
                label: '网站开发'
            },{
                imageUrl: miniApp,
                label: '小程序开发'
            },{
                imageUrl: publicImg,
                label: '公众号开发'
            },{
                imageUrl: app,
                label: 'app开发'
            },{
                imageUrl: support,
                label: '技术服务'
            },]
        };
    }

    render() {
        return (
            <div style={{marginTop: '80px',padding: '0 70px'}} id='service'>
                <MenuTitle  menuTitle="我们的服务" menuSubtitle="提供一站式软件外包开发服务"></MenuTitle>
                <div style={{display: 'flex',justifyContent: 'space-between',padding: '0 200px',marginTop: '100px'}}>
                    {
                        this.state.list.map((item,index) => {
                            return (
                                <div className="service-item">
                                    <img src={item.imageUrl} />
                                    <div className="fz-30 fw-600 cl-333 mt-18">{item.label}</div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
}